// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

// @primary-color: #1890ff;
/* You can add global styles to this file, and also import other style files */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

// html, body, div, span, applet, object, iframe,
// h1, h2, h3, h4, h5, h6, p, blockquote, pre,
// a, abbr, acronym, address, big, cite, code,
// del, dfn, em, img, ins, kbd, q, s, samp,
// small, strike, strong, sub, sup, tt, var,
// b, u, i, center,
// dl, dt, dd, ol, ul, li,
// fieldset, form, label, legend,
// table, caption, tbody, tfoot, thead, tr, th, td,
// article, aside, canvas, details, embed,
// figure, figcaption, footer, header, hgroup,
// menu, nav, output, ruby, section, summary,
// time, mark, audio, video {
// 	margin: 0;
// 	padding: 0;
// 	border: 0;
// 	font-size: 100%;
// 	font: inherit;
// 	vertical-align: baseline;
// }
// /* HTML5 display-role reset for older browsers */
// article, aside, details, figcaption, figure,
// footer, header, hgroup, menu, nav, section {
// 	display: block;
// }
// body {
// 	line-height: 1;
// }
// ol, ul {
// 	list-style: none;
// }
// blockquote, q {
// 	quotes: none;
// }
// blockquote:before, blockquote:after,
// q:before, q:after {
// 	content: '';
// 	content: none;
// }
// table {
// 	border-collapse: collapse;
// 	border-spacing: 0;
// }
// body{
//     font-family: 'GIP', sans-serif;
// 	font-size: 16px;
// }
// .btn{
// 	font-family: 'GIP', sans-serif;
// 	font-size: 16px;
// 	color: white;
// 	background-color: #2D66B1;
// 	margin: 0 10px;
// 	padding: 10px 15px;
// 	border: none;
// 	border-radius: 10px;
// 	outline : 0;
// 	-moz-outline : 0;
// 	border : 0;
// 	cursor: pointer;
// }

.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
  color: #fff;
}

.ant-collapse-icon-position-right
  > .ant-collapse-item-active
  > .ant-collapse-header {
  color: #fff;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #2d66b1;
}
.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #0e0f0f;
  border-color: #1890ff;
}
.ant-steps-item-wait .ant-steps-item-icon {
  background-color: #383a3a;
  border-color: rgba(0, 0, 0, 0.25);
}
.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
  color: #fff;
}
